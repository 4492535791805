import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Confetti from 'react-confetti';

const LetterReveal = ({ day, message, imageUrl, secondImageUrl, onClose }) => {
  const [isEnvelopeOpen, setIsEnvelopeOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
    height: typeof window !== 'undefined' ? window.innerHeight : 0
  });
  
  const isTouchDevice = typeof window !== 'undefined' && ('ontouchstart' in window);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleEnvelopeClick = () => {
    if (!isEnvelopeOpen) {
      setIsEnvelopeOpen(true);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 
                   p-3 sm:p-4 md:p-6 touch-none overflow-y-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {showConfetti && (
          <Confetti
            width={windowSize.width}
            height={windowSize.height}
            recycle={false}
            numberOfPieces={200}
            gravity={0.3}
            colors={['#fbbf24', '#b45309', '#f59e0b', '#d97706', '#fcd34d']}
            onConfettiComplete={() => setShowConfetti(false)}
          />
        )}

        <motion.div
          className="w-full max-w-2xl my-auto"
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
        >
          {/* Envelope Container */}
          <div 
            onClick={handleEnvelopeClick}
            className={`
              relative w-full bg-amber-300
              border-4 border-b-8 border-amber-400
              cursor-pointer select-none overflow-hidden
              ${!isEnvelopeOpen && !isTouchDevice && 'hover:scale-[0.98] transition-transform duration-100'}
              ${!isEnvelopeOpen ? 'aspect-[4/3] sm:aspect-video' : ''}
            `}
          >
            {!isEnvelopeOpen ? (
              // Closed Envelope State
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <div 
                  className="absolute top-0 left-0 right-0 h-1/2 bg-amber-200 
                            border-b-4 border-amber-400"
                >
                  <div className="absolute bottom-0 left-1/2 -translate-x-1/2 
                                w-0 h-0 border-solid
                                border-l-[25vw] border-r-[25vw] border-t-[20vw] 
                                sm:border-l-[15vw] sm:border-r-[15vw] sm:border-t-[10vw] 
                                border-l-transparent border-r-transparent border-t-amber-300
                                max-border-l-[100px] max-border-r-[100px] max-border-t-[60px]" />
                </div>
                <motion.div 
                  className="text-red-800 font-bold text-2xl md:text-3xl mb-2 pixel-shadow-sm"
                  animate={{ scale: [1, 1.05, 1] }}
                  transition={{ 
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  Jour {day}
                </motion.div>
                <motion.div 
                  className="text-red-800 font-mono text-sm md:text-base mt-2"
                  animate={{ opacity: [1, 0.5, 1] }}
                  transition={{ 
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  * click to open *
                </motion.div>
              </div>
            ) : (
              <>
                <motion.div 
                  className="absolute top-0 left-0 right-0 h-1/2 bg-amber-200
                            border-b-4 border-amber-400 origin-top"
                  initial={{ rotateX: 0 }}
                  animate={{ 
                    rotateX: 180,
                    transition: { 
                      duration: 0.6,
                      ease: [0.4, 0, 0.2, 1]
                    }
                  }}
                >
                  <div className="absolute bottom-0 left-1/2 -translate-x-1/2 
                                w-0 h-0 border-solid
                                border-l-[25vw] border-r-[25vw] border-t-[20vw] 
                                sm:border-l-[15vw] sm:border-r-[15vw] sm:border-t-[10vw] 
                                border-l-transparent border-r-transparent border-t-amber-300
                                max-border-l-[100px] max-border-r-[100px] max-border-t-[60px]" />
                </motion.div>

                <motion.div 
                  className="relative bg-amber-100 m-2 sm:m-3 md:m-4  overflow-y-auto"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.4 }}
                >
                  <div className="p-2 sm:p-4 md:p-6 flex flex-col gap-6 sm:gap-8">
                    <div className="space-y-4">
                      <motion.h2 
                        className="text-lg sm:text-xl md:text-2xl font-bold text-red-800 pixel-shadow-sm"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5 }}
                      >
                        Jour {day}
                      </motion.h2>
                      <motion.p 
                        className="text-red-800 font-mono text-sm sm:text-sm md:text-base leading-relaxed whitespace-pre-line"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.6 }}
                      >
                        {message}
                      </motion.p>
                    </div>

                    <motion.div 
                      className="bg-amber-50 border-4 border-amber-400"
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ delay: 0.7 }}
                    >
                      <img
                        src={imageUrl}
                        alt={`Day ${day} surprise`}
                        className="w-full h-auto"
                        loading="eager"
                      />
                      {secondImageUrl && (
                        <img
                          src={secondImageUrl}
                          alt={`Day ${day} second surprise`}
                          className="w-full h-auto mt-4"
                          loading="eager"
                        />
                      )}
                      <div className="h-8 sm:h-12 md:h-16" />
                    </motion.div>
                  </div>
                </motion.div>
              </>
            )}
          </div>

          {/* Close Button */}
          <div className="fixed bottom-4 left-0 right-0 px-4 text-center sm:static sm:mt-6">
            <motion.button
              onClick={onClose}
              className="bg-red-700 text-amber-200 py-2 px-6 
                       border-4 border-b-8 border-red-900 
                       active:border-b-4 active:translate-y-1
                       transition-all duration-100 font-bold
                       text-sm sm:text-base w-full sm:w-auto
                       touch-manipulation"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              whileHover={!isTouchDevice ? { scale: 0.98 } : {}}
              whileTap={{ scale: 0.95 }}
            >
              CLOSE
            </motion.button>
          </div>
        </motion.div>

        <style jsx>{`
          .pixel-shadow-sm {
            text-shadow: 
              1px 1px 0 #692424,
              2px 2px 0 #461818;
          }
          .max-border-l-[100px] { max-width: 100px; }
          .max-border-r-[100px] { max-width: 100px; }
          .max-border-t-[60px] { max-width: 60px; }
        `}</style>
      </motion.div>
    </AnimatePresence>
  );
};

export default LetterReveal;