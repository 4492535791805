import React, { useState, useEffect } from 'react';
import LetterReveal from './LetterReveal';
import { getContentForDay } from './AdventContent';

const LoginScreen = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  useEffect(() => {
    // Check if password is configured
    if (!process.env.REACT_APP_CALENDAR_PASSWORD) {
      console.error('Error: REACT_APP_CALENDAR_PASSWORD environment variable is not set');
      setError('Calendar is not properly configured. Please contact the administrator.');
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Prevent login attempts if password isn't configured
    if (!process.env.REACT_APP_CALENDAR_PASSWORD) {
      setError('Calendar is not properly configured. Please contact the administrator.');
      return;
    }
    
    if (password === process.env.REACT_APP_CALENDAR_PASSWORD) {
      onLogin();
    } else {
      setError('Invalid password');
      setPassword('');
    }
  };

  return (
    <div className="min-h-screen bg-red-800 p-4 font-mono flex flex-col items-center justify-center">
      <div className="text-center mb-12">
        <h1 className="text-3xl md:text-4xl font-bold text-amber-200 mb-4 pixel-shadow">
          CALENDRIER DE L'AVENT DE AUDREY: 3EME EDITION
        </h1>
        <div className="text-amber-300 text-sm md:text-base">
          * entrer le mot de passe pour découvrir votre surprises *
        </div>
      </div>

      <form 
        onSubmit={handleSubmit}
        className="w-full max-w-sm bg-amber-200 p-6 border-4 border-b-8 border-amber-400"
      >
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full px-4 py-2 mb-4 bg-amber-100 border-2 border-b-4 border-amber-400 
                     font-mono text-lg focus:outline-none focus:border-amber-500"
          placeholder="Enter password..."
          autoFocus
          disabled={!process.env.REACT_APP_CALENDAR_PASSWORD}
        />
        
        <button 
          type="submit"
          className="w-full bg-red-700 text-amber-200 py-2 px-4 border-4 border-b-8 
                     border-red-900 hover:bg-red-600 active:border-b-4 
                     transition-all duration-100 font-bold
                     disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={!process.env.REACT_APP_CALENDAR_PASSWORD}
        >
          ENTER
        </button>

        {error && (
          <div className="mt-4 text-red-700 text-center font-bold">
            {error}
          </div>
        )}
      </form>
    </div>
  );
};


const CalendarBox = ({ day, isAvailable, onClick }) => (
  <button
    onClick={() => isAvailable && onClick(day)}
    className={`
      aspect-square w-full
      border-4 border-b-8 
      flex items-center justify-center
      transition-transform hover:scale-95 active:scale-90
      font-bold text-2xl
      ${isAvailable 
        ? 'bg-amber-200 border-amber-400 hover:bg-amber-300 text-amber-800 cursor-pointer' 
        : 'bg-gray-200 border-gray-400 cursor-not-allowed text-gray-500'
      }
    `}
  >
    {day}
  </button>
);

const Calendar = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  
  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();

  const isAvailable = (day) => {

    if (process.env.REACT_APP_CALENDAR_DEBUG_MODE === 'true') return true;
    
    if (today.getFullYear() > 2024) return true;
    
    if (currentMonth === 11) {
      return day <= currentDay;
    }
    return true;
  };

  const shuffledDays = Array.from({ length: 24 }, (_, i) => i + 1)
    .sort((a, b) => {
      const hashA = (a * 13) % 24;
      const hashB = (b * 13) % 24;
      return hashA - hashB;
    });

  return (
    <div className="min-h-screen bg-red-800 p-4 font-mono">
      <div className="mb-8 text-center">
        <h1 className="text-2xl md:text-4xl font-bold text-amber-200 mb-2 pixel-shadow">
        CALENDRIER DE L'AVENT DE AUDREY 3EME EDITION
        </h1>
        <div className="text-amber-300 text-sm md:text-base">
          * cliquer sur une case pour découvrir votre surprise quotidienne *
        </div>
      </div>

      <div className="max-w-lg mx-auto">
        <div className="grid grid-cols-3 sm:grid-cols-4 gap-3 md:gap-4">
          {shuffledDays.map((day) => (
            <CalendarBox
              key={day}
              day={day}
              isAvailable={isAvailable(day)}
              onClick={setSelectedDay}
            />
          ))}
        </div>
      </div>

      {selectedDay && (
        <LetterReveal
          day={selectedDay}
          {...getContentForDay(selectedDay)}
          onClose={() => setSelectedDay(null)}
        />
      )}

      <div className="text-center mt-8 text-amber-200 text-sm">
        © 2024 · made with ♥ by Sami
      </div>
    </div>
  );
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <>
      {!isLoggedIn ? (
        <LoginScreen onLogin={() => setIsLoggedIn(true)} />
      ) : (
        <Calendar />
      )}
      
      <style jsx global>{`
        .pixel-shadow {
          text-shadow: 
            2px 2px 0 #692424,
            4px 4px 0 #461818;
        }
      `}</style>
    </>
  );
};

export default App;