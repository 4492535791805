export const adventContent = {
    1: {
      message: `Bonjour Audrey. \n\n Bienvenue dans la troisième édition du merveilleux calendrier de l'avent d'Audrey pour une merveilleuse fille. Je t'offre ce que j'ai de plus précieux et que je n'aurai jamais en abondance : mon temps. Malgré la distance, cette édition a été préparée avec autant de minutie et d'amour que les précédentes. 

      \n Cette édition est écrite sous le spectre de l'optimisme et de l'amour. Je pense que tu es une fille formidable, intelligente, belle et forte. Tu es aussi une fille tourmentée dans un monde sans ambition qui s'interdit de rêver. Une petite fleur qui réalisera bientôt que le soleil qui lui manque est juste au-dessus de sa tête.
      
      Sami qui t'aime. \n
      
      P.S. : Bien que l'édition soit virtuelle, n'oublie pas de regarder dans ta boîte aux lettres.`,
      imageUrl: "/images/1.png"  // You'll store your images in the public folder
    },
    2: {
      message: `Bonjour Audrey, \n

      Déjà le deuxième jour. Tu vas sûrement ouvrir ce message avec le précédent, puisque j'ai peut-être passé tout mon dimanche 1er décembre à coder cette petite application. Pas mal assisté par ces très chers outils d'intelligence artificielle, que ce soit sur le plan du code ou sur les images habilement générées. C'est quand même fou ce qu'on peut faire aujourd'hui, et ce n'est que le début. Je ne pense pas que l'on soit prêts pour le futur. On risque d'avoir plus d'évolutions dans les 10 prochaines années qu'on en a eu dans les 30 dernières. Et malgré cela, chaque jour se ressemble.
      
      Je t'aime.
      Sami`,
      imageUrl: "/images/2.png"
    },
    3: {
      message: `Bonjour Audrey. 

      Hier, on parlait de progrès. C'est sûr qu'on avance vite, peut-être pas toujours dans la bonne direction. Je ne sais pas si tu as reconnu l'image, cela vient de mon film préféré, "Le Château dans le ciel". C'est une magnifique histoire d'amour entre deux ados insouciants, complètement détachée du rapport homme/femme classique. Mais c'est aussi une vision de la technologie qui respecte la nature : les robots du Château dans le ciel sont en totale harmonie avec la faune locale. C'est en totale opposition avec la technologie d'aujourd'hui, mais ça donne de l'espoir : technologie et écologie ne sont pas incompatibles.
      
      Enfin bref, peut-être qu'il faut que tu crées des robots verts si tu veux sauver l'humanité ? 
      
      Je t'aime.
      Sami`,
      imageUrl: "/images/3.png"
    },

    4: {
      message: `Bonjour Audrey,
      
      C'est déjà le quatrième jour, le temps passe si vite, c'est fou. Hier on parlait de construire des robots. C'est vrai que ça paraît inaccessible. Est-ce qu'il ne faut pas 5 ans d'études pour être capable de construire des choses utiles ?

      As-tu déjà entendu parler des frères Wright ? Ils ont tout simplement lancé l'âge d'or de l'aviation en créant le premier avion. Sans aucun diplôme, ils travaillaient dans un atelier de vélos. Leur principal concurrent, mathématicien financé par l'État, s'est écrasé à son premier vol.

      Bref, tu as au moins autant de chances de créer quelque chose de grand que des experts ou scientifiques.

      Sami,
      Je t'aime`,
      imageUrl: "/images/4.png"
    },

    5: {
      message: `Bonjour Audrey,

      Peut-être qu'il manque juste des figures féminines qui entreprennent de grands projets techniques pour faire rêver toutes les petites filles et les éloigner de la superficialité ? Pourquoi ce serait qu'un truc de garçons de réparer des vélos ou de fabriquer des robots ? La graisse de vélo, c'est le nouveau mascara ?
      
      N'empêche, ces images envoient du lourd. Je suis sûr que tu aurais la classe dans un bleu de travail.
      
      Sami.
      Je t'aime`,
      imageUrl: "/images/5_2.jpeg",
      secondImageUrl: "/images/5_1.jpeg"
    },

    6: {
      message: `Bonjour Audrey,

      Déjà le 6 décembre ! Je te rajoute des petits bangers avec de nouvelles photos dans le thème d'hier. Vraiment, je les trouve très inspirantes.
      
      C'est quand même fou de se dire qu'on vit dans un monde où n'importe qui peut accumuler autant de connaissances que n'importe quel scientifique juste en se nourrissant de contenu peut-être un peu indigeste mais au moins librement accessible sur internet.
      
      Sami.
      Je t'aime.`,
      imageUrl: "/images/6_2.jpeg",
      secondImageUrl: "/images/6_1.jpeg"
    },

    7: {
      message: `Bonjour Audrey, ou bonne nuit devrais-je dire, je ne sais pas. En tout cas, ce petit bout de calendrier avance et ça fait déjà une semaine que l'aventure a commencé !

      Sortons un peu de l'univers techno-optimiste accélérationniste où tout est possible et regardons déjà tout le chemin que tu as parcouru.
      
      Combien de filles (et même d'hommes) connais-tu qui soient autant investies dans un sport aussi physique que le rugby ? Tu vas me répondre : "Bah, j'en connais plein !" Et bien, c'est sûr que tu t'entraînes et joues avec elles. Mais dis-toi bien que c'est une infime partie de la population qui est physiquement dévouée à une activité purement altruiste comme le sport, d'autant plus un sport d'équipe aussi dur que le rugby. C'est un magnifique contraste avec le superficiel qui règne en maître dans notre monde.
      
      Et le tout en étant toujours aussi belle avec ton maillot au cœur de l'action.
      
      Bisous.
      
      Sami
      Je t'aime`,
      imageUrl: "/images/7.png"
    },

    8: {
      message: `Bonjour Audrey,
      Que d'émotions hier ! C'est dimanche aujourd'hui, ou bien samedi soir alors tu es déjà au pic de ton humeur, on va donc faire plus léger pour préserver ce bel effet montagnes russes. Mais ne t'inquiète pas, ça va repartir de plus belle, de l'amour, de l'ambition, du rêve, ce n'est que le début. C'est une forme de relation épistolaire au final ce petit calendrier de l'avent. Ce sont nos "Liaisons dangereuses" à nous. Je me dis qu'il n'y a pas beaucoup de couples qui ont l'occasion de s'écrire des petites lettres d'amour comme ça. Un peu hors sujet mais je te conseille vraiment d'écouter le nouvel album de Bouss. Je l'écoute pour écrire et ça me met dans un mood mélancolique tout à fait optimal pour m'exprimer. J'imagine que tu as ressenti l'intensité que je mets dans chaque mot.
      Sami
      Je t'aime
      `,
      imageUrl: "/images/8.jpeg"
    },

    9: {
      message: `Bonjour Audrey. 
      
      J'espère que tu as bien profité des gaufres et des frites de Bruxelles. Ça devait être bon, je suis jaloux.

      C'est le début de la semaine, je vais donc faire de mon mieux pour te remonter le moral. Première bonne nouvelle : on est à trois semaines de Noël, et à deux semaines de ton anniversaire, mais aussi de mon arrivée. J'ai hâte et je pense qu'on va bien s'amuser à ta soirée d'anniversaire ainsi que la chaude nuit qui va s'ensuivre. Deuxième bonne nouvelle : c'est bientôt les vacances. J'ai hâte de finalement me reposer et profiter de mon temps avec toi.
      
      En tout cas, si tu devais retenir une chose de cette petite lettre, c'est que d'enlever cette boule au ventre du dimanche soir est ce qui pourra t'arriver de mieux.
      
      Bisous, Sami. Je t'aime.
      `,
      imageUrl: "/images/9.jpg"
    },

    10: {
      message: `Bonjour Audrey,

      Le week-end est passé ainsi que le blues du lundi. C'est donc le moment de prendre une belle bouffée d'air frais, d'espoir et d'optimisme.
      
      On vit à une époque incroyablement folle, avec un accès à la connaissance instantané, et on pourra même bientôt acheter de la matière grise : nos très chers amis les IA, capables d'aider n'importe qui à réfléchir sur des sujets en profondeur sans avoir besoin d'être un expert.
      
      Je pense qu'être à la pointe de la science et être un expert ne sera bientôt plus réservé à un petit groupe de doctorants boutonneux.
      
      Connais-tu Ada Lovelace ? Littéralement la première informaticienne de tous les temps, qui développa les ancêtres des langages de programmation au 19ème siècle. Son impact est tel que les dernières générations d'ordinateurs de chez Nvidia, sur lesquels GPT-4 a été entraîné, portent son nom. Si elle a pu le faire en 1815, quand les femmes n'avaient aucun droit, alors toi aussi, si ça te chante.
      
      Ce que j'essaie de dire, c'est que tu dois t'autoriser à rêver. Devenir une scientifique ? C'est possible. Est-ce que c'est ce que tu devrais faire ? Aucune idée, mais si tu ne considères pas cette option, ainsi que d'autres, c'est que ton spectre d'options est trop étroit.
      
      Sami.
      Je t'aime et crois en toi.
      `,
      imageUrl: "/images/10.jpg"
    },

    11: {
      message: `Bonjour Audrey, ou plutôt bonne nuit.

      Pas une âme de scientifique ce soir ? Pourquoi pas réalisatrice de films ? Encore un domaine qui a radicalement été révolutionné. On ne se rend pas compte à quel point on peut produire des films de qualité avec très peu de moyens. C'est presque absurde à quel point l'accès aux technologies de l'audiovisuel a été banalisé. Bon, au final, l'humanité utilise la majorité des caméras 4K des iPhones pour se filmer en train d'ouvrir des conneries sur TikTok, mais c'est uniquement parce qu'on vit dans une médiocrité profonde dans un monde sans aucune ambition. Mais le potentiel est là : chaque être humain avec un accès à un téléphone est un potentiel futur Tarantino.
      
      Alors pourquoi pas toi ? Et si, au hasard, tu te lances dans une suite de "Pulp Fiction", je ferais un bon acteur principal.
      
      Sami.
      Je t'aime.

      `,
      imageUrl: "/images/11.png"
    },

    12: {
      message: `Bonjour Audrey,

      J'espère que tu as bien aimé mon petit montage d'hier.
      
      On vit dans un monde sans aucune ambition. Et ça s'observe à tous les niveaux, riche ou pauvre, sud, nord, est ou ouest, partout. Les plus riches, étant au sommet de la médiocrité, ayant pourtant accès à une infinité de possibilités, préfèrent dépenser dans le luxe, le futile, l'apparence. Des drogués qui dépensent leur argent compulsivement après avoir regardé la pub de trop et s'en vantent. Le plus absurde étant que c'est ce qui fait rêver 99% du reste de la population. Aucune ambition au-delà de préserver son statut social et de succomber aux besoins dictés par la publicité.
      
      Ce constat semble fataliste, mais il est en fait très positif. Il suffit de changer son échelle de valeurs. La connaissance, le progrès, la science et l'art n'ont pour prix que l'effort et la persévérance. TOUT EST POSSIBLE.
      
      De plus, je pense que tu es déjà bien avancée sur ce que tu accordes de la valeur, bien loin de la médiocrité ambiante. Il ne te reste plus qu'à rêver !
      
      Je t'aime.      
      `,
      imageUrl: "/images/12.png"
    },

    13: {
      message: `Bonjour Audrey,

      Allez, petite pause dans l'accélérationnisme américain. Si l'on parlait de choses qui font plaisir. Moi, je pense qu'il faut que tu vives au soleil, près de l'océan, dans un climat tempéré mais pas trop chaud. Un peu comme la Californie ? Je te verrais bien aller surfer le matin en semaine et le week-end, même en décembre. Il faudrait bien sûr éviter les requins et surtout ne pas être aveuglée par les magnifiques paysages. Pendant que tu surferas, je ferai du vélo dans les montagnes en te surveillant de là-haut. On pourra prendre de bons petits déjeuners dans les cafés bobos de San Francisco et faire des petits road trips le week-end. Ou plutôt des vélos trips. Ça me plaît bien comme idée, on s'amuserait bien.
      
      Je t'aime, Bisous.
      
      `,
      imageUrl: "/images/13.jpg"
    },

    14: {
      message: `Bonjour Audrey,

      C'est fou ça fait déjà deux semaines que ce merveilleux calendrier de l'avent à commencé, deux semaines que tu te lèves le matin ou couche le soir en lisant mes petits mots, si c'est pas mignon, ça pourrait faire un petit film sympa.
      
      "Malgré la distance ce jeune couple entretien leur amour par des petits attention quotidien, une époqué épistolaire unique À l'ere du numérique. La fin du calendrier marque aussi leurs retrouvailles, célèbre avec autant de romantisme que la langue française le permette: enroulé l'une dans l'autre ainsi qu'autour du sexe du partenaires. La nuit ne se terminera pas, puisque ils se livreront à une sauvage session de jambe en l'air. Ils vivront heureux et eure beaucoup d'enfants."
      
      Peut être un peu trop chaud pour Netflix ...
      
      Je t'aime.
      Sami
      
      
      PS: pas de photos ce soir je laisse jouer ton imagination,

      `,
    },

    15: {
      message: `Bonjour Audrey,

      J'espère que tu as bien aimé le film hier. Au menu d'aujourd'hui, une nouvelle fiction tout aussi réaliste qu'hier, cependant, contrairement à mon récit optimiste habituel, je vais m'essayer à la dystopie futuriste.
      
      Les 10 prochaines années, peut-être même moins, sont les plus importantes que l'humanité n'a jamais connues. De la même façon que la force physique n'a plus d'importance au 21ème siècle, la matière grise, l'intelligence, le haut QI nécessaire pour réussir ces études, ce sera plus rien de positivement discriminant. L'intelligence deviendra une commodité, remplacée par le dur labeur des ordinateurs, de l'intelligence artificielle, docile et assidue. Que restera-t-il pour se différencier ? Plus grand-chose. L'ascenseur social, déjà en panne, va se rouiller pour l'éternité.
      
      Heureusement, les cartes sont redistribuées, nouvelle ère, nouvelles règles, tout peut encore se jouer, tout est possible surtout, littéralement tout peut potentiellement se réaliser. Il n'y a pas d'ère plus adéquate pour avoir 25 ans. C'est maintenant ou jamais pour bondir hors des rangs.
      
      Je t'aime, Sami.`,
      imageUrl: "/images/15.jpeg"
    },

    21: {
      message: `Bonjour Audrey \n, Ce soir c'est ta soirée d'anniversaire. Tu vas sûrement recevoir beaucoup de cadeaux ! Le plus important étant bien sûr mon arrivée, après de longs mois d'attente. Et puis il fait froid aujourd'hui alors je serai entouré de plusieurs couches de papier cadeau. Je suis sûr que tu prendras un malin plaisir à tout déballer. Ne sois pas trop gourmande et ne mange pas tout d'un coup puisque tu as plusieurs semaines pour tout savourer. Mais bon, c'est les fêtes, ta fête, alors fais-toi plaisir tant que tu ne t'étouffes pas.

      Passons cet épisode grivois, j'ai hâte d'arriver et de te serrer dans mes bras. Je t'aime. Sami
      
      `,
      imageUrl: "/images/21.png"
    },

    22: {
      message: `Joyeux anniversaire Audrey. J'ai dû arriver hier tout juste à l'heure pour ton anniversaire. J'espère qu'on s'est bien amusés, et puis on ne s'est pas vus pendant plusieurs mois, on avait probablement beaucoup de choses à se dire, on n'a pas dû beaucoup dormir.

      \n 26 ans, je suis sûr que tu te dis que tu vieillis, mais si seulement tu te rendais compte à quel point on est encore jeunes. Il y a littéralement tous les futurs possibles devant nous. On peut ensemble devenir qui l'on veut. Surtout que l'existence d'un individu ne s'arrête pas à sa courte vie, c'est l'impact qu'il a sur les autres, ce qu'il a inspiré, éduqué, guidé, aidé qui compte.
      
      \n Bref, si jeune, je suis sûr que si, plus vieille, tu devais choisir un âge pour revenir dans le passé, tu choisirais aujourd'hui.
      
      Je t'aime.
      `,
      imageUrl: "/images/22.png"
    }
    // ... Add entries for days 4-24
  };
  
  // Optional: Add metadata or helper functions
  export const getContentForDay = (day) => {
    if (!adventContent[day]) {
      return {
        message: `Surprise message for day ${day}! Content coming soon...`,
        imageUrl: "/images/placeholder.jpg"
      };
    }
    return adventContent[day];
  };